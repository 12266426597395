import type { OperationVariables, QueryFunctionOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { RecommendedWallet } from '@app/interfaces/RecommendedWallet';
import { RecommendedWalletQuery } from '../queries/RecommendedWallet';

export const useRecommendedWallet = ({
  onSuccess,
}: useRecommendedWalletProps) =>
  useQuery<Returned>(RecommendedWalletQuery, {
    onCompleted: onSuccess,
  });

type useRecommendedWalletProps = {
  onSuccess?: QueryFunctionOptions<Returned, OperationVariables>['onCompleted'];
};

type Returned = {
  wallets: RecommendedWallet[];
};

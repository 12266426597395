import type { OperationVariables, QueryFunctionOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { Fundamentalist } from '@app/interfaces/Fundamentalist';
import { FundamentalistQuery } from '../queries/Fundamentalist';

export const useFundamentalist = ({ onSuccess }: useFundamentalistProps) =>
  useQuery<Returned>(FundamentalistQuery, {
    onCompleted: onSuccess,
  });

type useFundamentalistProps = {
  onSuccess?: QueryFunctionOptions<Returned, OperationVariables>['onCompleted'];
};

type Returned = {
  data: Fundamentalist[];
};

import { parseCookies, setCookie } from 'nookies';

type SaveCookieOptions = {
  path?: string;
  maxAge?: number;
};

type SaveCookieParams = {
  key: string;
  data: unknown;
  options?: SaveCookieOptions;
};

export const getCookie = (key: string) => {
  const cookie = parseCookies(null);
  return cookie[key];
};

export const saveCookie = ({ data, key, options }: SaveCookieParams) => {
  const parsedData =
    typeof data === 'object' ? JSON.stringify(data) : String(data);

  setCookie(null, key, parsedData, { ...options });
};

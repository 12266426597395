import { gql } from '@apollo/client';

export const FundamentalistQuery = gql`
  query Data {
    data(sortBy: CREATEDAT_ASC, query: { status_in: ["active"] }, limit: 1000) {
      capitalizacaoDeMercado
      desempenho12M
      desempenho30D
      desempenhoANO
      dividendYieldANO
      dividendYieldANO1
      empresa
      evEbitdaANO
      evEbitdaANO1
      pLANO
      pLANO1
      pVpa
      potencialDeValorizacao
      precoAlvo
      precoAtual
      precoMaximo52S
      precoMinimo52S
      recomendacao
      segmento
      setor
      ticker
      volumeMedioNegociado30D
    }
  }
`;

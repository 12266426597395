import type { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { cookiesConfig } from '@app/config/cookies';
import { saveCookie } from '@app/utils/cookies';

const utmKeys = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
  'gclid',
  'campaignId',
  'assessor',
  'manual_referral',
  'partner_tag',
];

const getUtm = (query: NextParsedUrlQuery) => {
  const utmData = {};
  Object.entries(query).forEach(([key, value]) => {
    if (utmKeys.includes(key)) utmData[key] = value;
  });
  return utmData;
};

export const useSaveUtm = () => {
  const router = useRouter();

  useEffect(() => {
    const utmData = getUtm(router.query);
    const hasUtmData = !!Object.keys(utmData).length;

    if (hasUtmData) {
      saveCookie({
        key: cookiesConfig.keys.utm,
        data: utmData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);
};

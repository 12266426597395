import { gql } from '@apollo/client';

export const RecommendedWalletQuery = gql`
  query Wallet {
    wallets(sortBy: CREATEDAT_DESC, query: { status_in: ["active"] }) {
      _id
      createdAt
      fileId
      filePath
      peso
      status
      ticker
    }
  }
`;
